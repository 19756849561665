import type {
  Bidder,
  Item,
  ItemDocument,
} from '~/types/artcurial/artcurial-domain';
import type { SeoMeta } from '~/types/cms/common';

import {
  type UseLotDescriptionResult,
  type UseLotDescriptionResultUndefined,
  useLotDescription,
} from './useLotDescription';

interface UseLotResultUndefined {
  description: UseLotDescriptionResultUndefined;
  hasReservePrice: ComputedRef<boolean>;
  mainImage: ComputedRef<ItemDocument | undefined>;
  seo: ComputedRef<SeoMeta>;
}

interface UseLotResult {
  description: UseLotDescriptionResult;
  hasReservePrice: ComputedRef<boolean>;
  mainImage: ComputedRef<ItemDocument | undefined>;
  seo: ComputedRef<SeoMeta>;
}

export function useLot(
  lot: Ref<Item>,
  bidder?: Ref<Bidder | undefined>
): UseLotResult;
export function useLot(
  lot: Ref<Item | undefined>,
  bidder?: Ref<Bidder | undefined>
): UseLotResultUndefined;

/**
 * Get the computed properties for a given lot
 * @param lot The lot to get the properties from
 * @returns The computed properties for the lot
 */
export function useLot(lot: Ref<Item | undefined>): UseLotResultUndefined {
  const { t } = useI18n();
  const description = useLotDescription(lot);
  const ogImg = useImage();

  const mainImage = computed(() => {
    return lot.value?.pictures?.find((media) => media?.index === 1);
  });

  const hasReservePrice = computed(() => {
    return lot.value?.reserveType !== 'NONE' || false;
  });

  const seo = computed<SeoMeta>(() => {
    if (!description.value) {
      // Default SEO values
      return {
        description: t('lots.seo.description'),
        ogDescription: t('lots.seo.description'),
        ogImage: ogImg('/artcurial-default_image.png', { width: 300 }),
        ogTitle: t('lots.seo.title'),
      };
    }

    return {
      description:
        description.value.shortDescription ||
        description.value.descriptionWithHtml,
      ogDescription:
        description.value.shortDescription ||
        description.value.descriptionWithHtml,
      ogImage: ogImg(
        mainImage.value?.document?.servingUrl || '/artcurial-default_image.png',
        { width: 300 }
      ),
      ogTitle: description.value.titleWithHtml,
    };
  });

  return {
    description,
    hasReservePrice,
    mainImage: mainImage,
    seo,
  };
}
